import React from "react";

const Home = () => {
  return (
    <main id="home">
      <div className="hero">
        <img
          alt="Woman cycling"
          className="hero-image"
          draggable="false"
          src="/images/home-hero.jpg"
        />
        <div className="hero-copy hero-copy_left">
          <h1>WORLD’S FIRST TEAM DRIVEN CYCLING STUDIO</h1>
          <p>
            We combine a powerful workout with the benefits of team sports for an experience that
            motivates you to maximize your results–wherever you are.
          </p>
          <a
            className="button"
            href="https://play.google.com/store/apps/details?id=com.zingfit.SWERVE_Fitness&hl=en_US&gl=US"
            rel="noopener noreferrer"
            target="_blank"
          >
            Try the app
          </a>
        </div>
      </div>
      <div className="buttonPair home-buttonPair_buyBikeForBusiness">
        <a href="/bike" className="button">
          Buy the bike
        </a>
        <a href="#" className="button button_outline">
          Swerve for business
        </a>
      </div>
      <div className="home-verticalLine"></div>
      <div className="home-loseYourLimits">
        <h2>Lose your limits</h2>
        <p>
          Show up, commit, compete, train,
          <br />
          work together, push each other, win.
        </p>
      </div>
      <img className="home-splitImage" src="/images/home-splitimage.jpg" />
      <div className="home-methodAndNowStreaming">
        <p className="home-methodAndNowStreaming-method">
          Our method is rooted in the mentality of team. Even when you’re on a bike solo in your
          living room, our training sessions are built to deliver the support and challenge you can
          only get from being a part of something bigger. You’ll be better for it.
        </p>
        <div className="home-methodAndNowStreaming-nowStreaming">
          <h3>Now streaming from our home in NYC to yours:</h3>
          <div className="buttonPair">
            <a href="/bike" className="button">
              Browse the bike
            </a>
            <a href="#" className="button button_outline">
              4 ways to ride
            </a>
          </div>
        </div>
      </div>
      <div className="home-noShortcuts">
        <div className="home-noShortcuts-intro">
          <h2>There are no shortcuts</h2>
          <p>
            Behind every training session is a proven scientific method, one that is built for your
            physical and psychological benefit. This method relies on three things:
          </p>
        </div>
        <div className="home-noShortcuts-threeThings">
          <div className="home-noShortcuts-threeThings-thing">
            <h2>The Workout</h2>
            <p>
              We get you the maximum output for your input. Whether your goal is weight loss or
              training for a triathlon, our focus on increasing exercise capacity and performance is
              what will get you there.
            </p>
          </div>
          <div className="home-noShortcuts-threeThings-thing">
            <h2>The Why</h2>
            <p>
              Stats to set your goals against. Playlists to get lost in. Trainers to drive you.
              Challenges to keep you engaged. As former Division I athletes, we know the mental
              aspect is justas important as the physical.
            </p>
          </div>
          <div className="home-noShortcuts-threeThings-thing">
            <h2>The Proof</h2>
            <p>
              We don’t take your time lightly. We’ve brought in outside experts to assess real
              changes in people who train with us.
            </p>
          </div>
        </div>
        <div className="home-noShortcuts-cta">
          <span>Learn the Science Behind Swerve</span>
          <a href="#" className="button">
            start today
          </a>
        </div>
      </div>
      <div className="home-swerveForBusiness">
        <div className="home-swerveForBusiness-copy">
          <h3>Swerve for Business</h3>
          <p>
            Swerve was built to bring people together around a common goal. When you incorporate
            Swerve into your offering, you create a space for friends to push one another and
            strangers to become teammates. Our method injects a new level of energy wherever it
            goes. There’s nothing like it.
          </p>
          <p>
            Our scientifically-proven training sessions are delivered through Technogym’s unrivaled
            technology and bikes for workouts that are always evolving and equipment that is built
            to last.
          </p>
          <p>
            From gyms to universities, hotels to corporations, and more, we’ve created a seamless
            path for any business to bring Swerve to its people.{" "}
          </p>
          <a href="#" className="button">
            learn more
          </a>
        </div>
        <img alt="Swerve employee" src="/images/swerve-employee.jpg" />
      </div>
      <div className="home-trainers">
        <h1 className="home-trainers-header_mobile">
          ELITE INDIVIDUALS
          <br />
          MAKE FOR ELITE TRAINERS
          <br />
          OUR TEAM IS THE BEST OF THE BEST
        </h1>
        <h1 className="home-trainers-header_desktop home-trainers-header_desktop-1">
          ELITE INDIVIDUALS MAKE FOR ELITE TRAINERS
        </h1>
        <div className="home-trainers-images">
          <img src="/images/trainer-temp.jpg" alt="Swerve trainer" />
          <img src="/images/trainer-temp.jpg" alt="Swerve trainer" />
          <img src="/images/trainer-temp.jpg" alt="Swerve trainer" />
          <img src="/images/trainer-temp.jpg" alt="Swerve trainer" />
        </div>
        <h1 className="home-trainers-header_desktop home-trainers-header_desktop-2">
          OUR TEAM IS THE BEST OF THE BEST
        </h1>
        <a href="/trainers" className="button button_outline home-trainers-cta">
          Meet your trainers
        </a>
      </div>
      <div className="testimonials gradientBG">
        <h2>Don’t Just Take Our Word for It</h2>
        <div className="testimonials-testimonials">
          <div className="testimonials-testimonial">
            <span>&ldquo;One of “The 10 Best Workout Classes for Men.&rdquo;</span>
            <img src="/images/logo-fitness.svg" alt="fitness logo" />
          </div>
          <div className="testimonials-testimonial">
            <span>&ldquo;Undefeated.&rdquo;</span>
            <img src="/images/logo-fitness.svg" alt="fitness logo" />
          </div>
          <div className="testimonials-testimonial">
            <span>&ldquo;The next big thing in fitness: Results.&rdquo;</span>
            <img src="/images/logo-fitness.svg" alt="fitness logo" />
          </div>
          <div className="testimonials-testimonial">
            <span>&ldquo;28 Most Innovative Gyms in America&rdquo;</span>
            <img src="/images/logo-fitness.svg" alt="fitness logo" />
          </div>
        </div>
      </div>
      <div className="home-ourStory">
        <div className="home-ourStory-intro">
          <h2>Our Story</h2>
          <img src="/images/our-story.jpg" alt="Swerve Founders" />
          <p>
            In 2013, we introduced the ﬁrst ever competition-based cycling studio. And people loved
            it. Over the last seven years we’ve watched riders across New York City show up, push
            themselves, and improve. We’ve watched them rack up personal bests, turn losses into
            motivation for the next win, and take on challenges above and beyond their daily workout
            routine. We were born on a bike. See how we’ve evolved.
          </p>
          <a href="#" className="button">
            learn more
          </a>
        </div>
      </div>
      <div className="home-embraceSwerve">
        <h2>#embracetheswerve</h2>
        <img
          className="home-embraceSwerve-symbol"
          src="/images/swerve-symbol.svg"
          alt="Swerve symbol"
        />
        <h2>Something bigger</h2>
        <h3>This is why we show up.</h3>
        <p>
          Every training session that you take, every bike that is purchased, every new business
          that adopts the Swerve method is contributing to the mission of pulling others up with us.
        </p>
        <a href="#" className="button">
          LEARN ABOUT OUR GIVE BACK PROGRAM
        </a>
      </div>
      <div className="blackSpacerBeforeFooter"></div>
    </main>
  );
};

export default Home;
