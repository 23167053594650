import React from "react";

const Trainers = () => {
  return (
    <main className="bricksBGRepeating" id="trainers">
      <h1 className="trainers-header">The Swerve Squad</h1>
      <div className="trainers-container">
        <div className="trainers-container-trainer">
          <a href="/trainers/eddy-cavazos">
            <img src="/images/trainer2-temp.jpg" alt="A trainer" />
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-name">Simon Lawson</span>
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-viewProfile">View Profile</span>
          </a>
        </div>
        <div className="trainers-container-trainer">
          <a href="/trainers/eddy-cavazos">
            <img src="/images/trainer2-temp.jpg" alt="A trainer" />
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-name">Cindy Harrington</span>
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-viewProfile">View Profile</span>
          </a>
        </div>
        <div className="trainers-container-trainer">
          <a href="/trainers/eddy-cavazos">
            <img src="/images/trainer2-temp.jpg" alt="A trainer" />
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-name">Terry Ball</span>
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-viewProfile">View Profile</span>
          </a>
        </div>
        <div className="trainers-container-trainer">
          <a href="/trainers/eddy-cavazos">
            <img src="/images/trainer2-temp.jpg" alt="A trainer" />
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-name">Jennie Foster</span>
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-viewProfile">View Profile</span>
          </a>
        </div>
        <div className="trainers-container-trainer">
          <a href="/trainers/eddy-cavazos">
            <img src="/images/trainer2-temp.jpg" alt="A trainer" />
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-name">Vicky Ferguson</span>
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-viewProfile">View Profile</span>
          </a>
        </div>
        <div className="trainers-container-trainer">
          <a href="/trainers/eddy-cavazos">
            <img src="/images/trainer2-temp.jpg" alt="A trainer" />
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-name">Sally Moran</span>
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-viewProfile">View Profile</span>
          </a>
        </div>
        <div className="trainers-container-trainer">
          <a href="/trainers/eddy-cavazos">
            <img src="/images/trainer2-temp.jpg" alt="A trainer" />
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-name">Travis Gill</span>
          </a>
          <a href="/trainers/eddy-cavazos">
            <span className="trainers-container-trainer-viewProfile">View Profile</span>
          </a>
        </div>
      </div>
      <a href="#" className="button trainers-cta">
        Join Swerve
      </a>
      <div className="blackSpacerBeforeFooter"></div>
    </main>
  );
};

export default Trainers;
