import React from "react";

const Footer = () => {
  return (
    <footer>
      <div className="footer-navLinksAndLetsTeamUp">
        <div className="footer-navLinks">
          <div className="footer-navLinks-column">
            <h6>About</h6>
            <a href="#">Our Story</a>
            <a href="#">The Bike</a>
            <a href="#">Careers</a>
            <a href="#">Press</a>
            <a href="#">Locations</a>
          </div>
          <div className="footer-navLinks-column">
            <h6>For Businesses</h6>
            <a href="#">Private Events</a>
            <a href="#">Offer Swerve</a>
          </div>
          <div className="footer-navLinks-column">
            <h6>Support</h6>
            <a href="/faq">FAQs</a>
            <a href="#">Contact</a>
            <a href="#">Return Policy</a>
          </div>
        </div>
        <div className="footer-letsTeamUp">
          <h3>Let’s Team Up.</h3>
          <div className="footer-newsletterSignUp">
            <p>
              Sign up for our community newsletter and we'll hook up with 10% off your first
              purchase.
            </p>
            <form action="#">
              <input type="email" placeholder="EMAIL ADDRESS" required />
              <input type="submit" value="SIGN UP" />
            </form>
            <p>Learn more about how we protect your personal data by viewing our privacy notice.</p>
          </div>
        </div>
      </div>
      <div className="footer-socialLinks">
        <a href="https://twitter.com/swerve_fitness" rel="noopener noreferrer" target="_blank">
          <img src="/images/social-icon-twitter.svg" />
        </a>
        <a
          href="https://www.instagram.com/swervefitness/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src="/images/social-icon-instagram.svg" />
        </a>
        <a
          href="https://www.linkedin.com/company/swerve-fitness"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src="/images/social-icon-linkedin.svg" />
        </a>
        <a href="https://www.facebook.com/swervefit/" rel="noopener noreferrer" target="_blank">
          <img src="/images/social-icon-facebook.svg" />
        </a>
      </div>
      <hr />
      <div className="footer-smallLinksAndCopyright">
        <div className="footer-smallLinks">
          <a href="/privacy">Privacy Policy</a>
          <a href="/accessibility">Accessibility</a>
          <a href="/terms-of-service">Terms of Service</a>
        </div>
        <div className="footer-copyright">
          <span>
            ©&nbsp;Swerve&nbsp;2020,&nbsp;Swerve&nbsp;Fitness,&nbsp;Inc.
            All&nbsp;rights&nbsp;reserved.
          </span>
          <img alt="Swerve symbol" draggable="false" src="/images/swerve-symbol-gray.svg" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
