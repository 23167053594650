import React from "react";

const WaysToRide = () => {
  return (
    <main className="bricksBGRepeating" id="waysToRide">
      <div className="waysToRide-intro">
        <h1>4 Ways to Ride</h1>
        <h4>Experience Swerve’s Challenging Training Method in Four Different Styles</h4>
      </div>
      <img
        alt="signature"
        className="waysToRide-bigTextImg"
        src="images/waystoride-signature.svg"
      />
      <div className="wayToRide wayToRide_iconRight">
        <img src="images/waystoride-signature-icon.svg" alt="Signature icon" />
        <div className="wayToRide-copy">
          <h3>Signature</h3>
          <p>
            This interval-based workout motivates you to consistently work harder and achieve more.
            Your coaches will train your mind to go harder because when you know there’s a team of
            riders alongside you, you’ll thrive on their energy and find yourself pushing for
            perpetual movement and improvement.
          </p>
          <p>
            Style: Sprints, rhythm-based challenges, and moments of reflection. Loved by: Athletes
            of all levels and all mindsets.
          </p>
        </div>
      </div>
      <img alt="beats" className="waysToRide-bigTextImg" src="images/waystoride-beats.svg" />
      <div className="wayToRide wayToRide_iconRight">
        <img src="images/waystoride-beats-icon.svg" alt="beats icon" />
        <div className="wayToRide-copy">
          <h3>Beats</h3>
          <p>
            We believe in the power of music to push you further. Occasionally you’ll even have a DJ
            spinning tracks live in this full-body, immersive workout where we sync your pedal
            strokes to the rhythm of the music and the riders alongside you. You’ll find the drive
            to push past your perceived limits and build new levels of strength and endurance
            throughout your ride.
          </p>
          <p>
            Style: Increasing resistance while maintaining the beat. Loved by: Anyone that finds
            motivation through music.
          </p>
        </div>
      </div>
      <img
        alt="unplugged"
        className="waysToRide-bigTextImg"
        src="images/waystoride-unplugged.svg"
      />
      <div className="wayToRide wayToRide_iconRight">
        <img src="images/waystoride-unplugged-icon.svg" alt="unplugged icon" />
        <div className="wayToRide-copy">
          <h3>Unplugged</h3>
          <p>
            We eliminate all stats and metrics in this ride so that you can unplug. With this
            stripped back experience your focus shifts inward to your body and mind. Your coach and
            the team of riders who are in it with you will shift your mindset, inspire you to dig
            deep, and set the stage for a breakthrough. You’ll build physical and mental strength as
            you jog, climb, and push together.
          </p>
          <p>
            Style: Take away the tech, clear your head, ride with your heart. Loved by: Anyone who
            lets their body guide their workout.
          </p>
        </div>
      </div>
      <img alt="power" className="waysToRide-bigTextImg" src="images/waystoride-power.svg" />
      <div className="wayToRide wayToRide_iconRight">
        <img src="images/waystoride-power-icon.svg" alt="power icon" />
        <div className="wayToRide-copy">
          <h3>Power</h3>
          <p>
            Both individual- and team-based goals are set throughout this ride. The pure challenge
            is what takes you to the next level. Think: pushes that are repeated in succession —
            fast, in-the-saddle pushes for speed, agility, and control, and shifting in and out of
            the saddle with heavier resistance for endurance. No upper body necessary.
          </p>
          <p>
            Style: Training for speed, agility, control and endurance. Loved by: The ambitious
            athlete and road cyclist.
          </p>
        </div>
      </div>
      <div className="wayToRide-outro">
        <p>
          Swerve is a team-driven brand built on the belief that we’re stronger together. Our
          workouts recreate the accountability, camaraderie, and fun of team sports by connecting
          your individual effort to the energy of the collective. When you’re a part of something
          bigger, you show up stronger, and the result is more powerful than anything you could
          achieve on your own.
        </p>
        <a href="/the-bike" className="button button_centered">
          buy the bike
        </a>
      </div>
      <div className="blackSpacerBeforeFooter"></div>
    </main>
  );
};

export default WaysToRide;
