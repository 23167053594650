import React from "react";

const Locations = () => {
  return (
    <main id="locations">
      <div className="locations-hero"></div>
      <div className="locations-postHero">
        <div className="locations-postHero-comingBack">
          <h1>Coming Back Soon</h1>
          <div className="locations-details">
            <p>
              Due to Covid-19 Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
              enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
              aliquip ex ea commodo consequat.
            </p>
            <div className="locations-details-subSection">
              <h3>Flatiron</h3>
              <div className="locations-details-address">
                <p>
                  30 W 18th St,
                  <br />
                  New York, NY 10011
                </p>
                <p>
                  <a href="mailto:info@swervefitness.com">info@swervefitness.com</a>
                  <br />
                  631-834-5566
                </p>
              </div>
            </div>
            <div className="locations-details-subSection locations-details-subSection_liveStream">
              <h3>On Live-Stream</h3>
              <p>
                Training Sessions now streaming
                <br />
                <a className="green" href="#">
                  Live on Zoom
                </a>
              </p>
              <a href="#" className="button">
                see full schedule
              </a>
            </div>
          </div>
        </div>
        <div className="locations-postHero-amenities">
          <h1>In-Studio Amenities</h1>
          <div className="locations-postHero-amenities-photos">
            <div className="locations-postHero-amenities-photos-photo">
              <img src="/images/greybox.png" alt="temp" />
              <p>Showers, Towels, and Products</p>
            </div>
            <div className="locations-postHero-amenities-photos-photo">
              <img src="/images/greybox.png" alt="temp" />
              <p>Complimentary Cycling Shoes</p>
            </div>
            <div className="locations-postHero-amenities-photos-photo">
              <img src="/images/greybox.png" alt="temp" />
              <p>Lockers With Built-In Locks</p>
            </div>
            <div className="locations-postHero-amenities-photos-photo">
              <img src="/images/greybox.png" alt="temp" />
              <p>On-Site Smoothie Bar</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Locations;
