import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.css";

import Footer from "./shared/components/footer.jsx";
import Header from "./shared/components/header.jsx";

import About from "./pages/about.jsx";
import FAQ from "./pages/faq.jsx";
import Home from "./pages/home.jsx";
import Locations from "./pages/locations.jsx";
import TheBike from "./pages/the-bike.jsx";
import WaysToRide from "./pages/ways-to-ride.jsx";
import TOS from "./pages/tos.jsx";
import Trainer from "./pages/trainer.jsx";
import Trainers from "./pages/trainers.jsx";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={"/swerve"}>
        <Header />
        <Switch>
          {/* PUBLIC PAGES */}
          {/* Homepage */}
          <Route exact path="/">
            <Home />
          </Route>

          {/* About page */}
          <Route exact path="/about">
            <About />
          </Route>

          {/* FAQ page */}
          <Route exact path="/faq">
            <FAQ />
          </Route>

          {/* FAQ page */}
          <Route exact path="/locations">
            <Locations />
          </Route>

          {/* ToC page */}
          <Route exact path="/terms-of-service">
            <TOS />
          </Route>

          {/* The Bike page */}
          <Route exact path="/the-bike">
            <TheBike />
          </Route>

          {/* Trainers page */}
          <Route exact path="/trainers">
            <Trainers />
          </Route>

          {/* Trainers page */}
          <Route path="/trainers/:trainer">
            <Trainer />
          </Route>

          {/* Ways To Ride page */}
          <Route exact path="/ways-to-ride">
            <WaysToRide />
          </Route>

          {/* Everything else = 404 */}
          <Route path="*">
            <h1>404 Not Found</h1>
          </Route>
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
