import React from "react";

const About = () => {
  return (
    <main id="about">
      <div className="hero hero_noMarginBelow">
        <img
          alt="Woman cycling"
          className="hero-image"
          draggable="false"
          src="/images/about-hero.jpg"
        />
        <div className="hero-copy hero-copy_left">
          <h1>WE WIN WHEN YOU WIN.</h1>
          <p>
            Swerve challenges all people, at all levels to dig deeper. We use science-backed methods
            for a fitness experience that heightens your motivation through personal progress,
            competitive challenges, and playlists that make you move.
          </p>
        </div>
      </div>
      <div className="textPage swooshBG swooshBG_gray">
        <div className="textPage-copyBlock">
          <h3>Our Story</h3>
          <p>We took a shot, that’s how we look at it.</p>
          <p>
            When we met in grade school we never would have guessed that one day we’d be leaving our
            jobs to create a cycling studio together. All three of us co-founders — Chelsea, JH, and
            Eric — were lacrosse players in college. After years of benefiting from team
            accountability, a structured routine, and competition that required us to be better, we
            felt it all slipping away as we transitioned to desk jobs and the typical fitness
            options.
          </p>
          <p>
            Despite being led by an instructor, and being surrounded by people in studio workouts,
            we found that we’d get a decent workout, at best. We weren’t being pushed to the level
            that we had been before. We weren’t actually benefiting from the energy of the other
            people in the room. And they weren’t benefiting from us. We were going through the
            motions and then getting on with the day.{" "}
          </p>
          <p>
            <b>WE WANTED MORE. WE NEEDED MORE.</b>
          </p>
          <p>
            So we put it all on the line. We pooled our life savings and left the comfort of our
            jobs.
          </p>
          <p>
            In 2013, we introduced the world’s first team-cycling studio. And people loved it. Over
            the last seven years we’ve watched riders across New York City show up, push themselves,
            and improve. We’ve watched them rack up personal bests, turn losses into motivation for
            the next win, and take on challenges above and beyond their daily workout routine. We’ve
            watched a real community grow. Not just the kind you find on social media. It turns out
            that the same things that helped us thrive out on the lacrosse field as a team were the
            same things that would bring people together in a NYC cycling studio.{" "}
          </p>
          <p>
            Today we’re the same three entrepreneurs that continue to want more, except now we’re
            grateful to have built a loyal and motivated team of people that want the same.{" "}
          </p>
          <p>We were born on a bike. But we’ve evolved.</p>
          <a href="#" className="button button_centered">
            READ MORE ABOUT OUR OFFERING
          </a>
        </div>
      </div>
      <div className="about-swooshphotosection">
        <div className="about-swooshphotosection-subsection about-swooshphotosection-theBike">
          <h3>Introducing the Bike</h3>
          <p>
            Train on your terms with our immersive experience. The bike redefines how you workout at
            home with live and recorded sweat sessions from our top-tier trainers.
          </p>
          <a href="/the-bike" className="button">
            SHOP THE BIKE
          </a>
        </div>
        <div className="about-swooshphotosection-subsection about-swooshphotosection-theTeam">
          <h3>Join the Team</h3>
          <p>We’re always looking for trainers and talent to join our team.</p>
          <a href="/trainers" className="button button_spaceBelow">
            MEET THE TRAINERS
          </a>
          <h3>Be a Part of Something Bigger</h3>
          <a href="/the-bike" className="button">
            open roles
          </a>
        </div>
      </div>
      <div className="testimonials gradientBG">
        <h2>Don’t Just Take Our Word for It</h2>
        <div className="testimonials-testimonials">
          <div className="testimonials-testimonial">
            <span>&ldquo;One of “The 10 Best Workout Classes for Men.&rdquo;</span>
            <img src="/images/logo-fitness.svg" alt="fitness logo" />
          </div>
          <div className="testimonials-testimonial">
            <span>&ldquo;Undefeated.&rdquo;</span>
            <img src="/images/logo-fitness.svg" alt="fitness logo" />
          </div>
          <div className="testimonials-testimonial">
            <span>&ldquo;The next big thing in fitness: Results.&rdquo;</span>
            <img src="/images/logo-fitness.svg" alt="fitness logo" />
          </div>
          <div className="testimonials-testimonial">
            <span>&ldquo;28 Most Innovative Gyms in America&rdquo;</span>
            <img src="/images/logo-fitness.svg" alt="fitness logo" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default About;
