import React from "react";

const FAQ = () => {
  return (
    <main id="faq">
      <div className="textPage swooshBG">
        <div className="textPage-copyBlock">
          <h1>FAQs</h1>
          <p>
            <strong className="faq-question">How do I get started?</strong>
            Sign up to create an account, buy classes, and reserve to SWERVE!
          </p>
          <p>
            <strong className="faq-question">How do I reserve a class?</strong>
            In order to reserve a bike, you must first purchase classes. To buy classes, sign up for
            a username and password or if you’ve already created an account, you can simply login to
            purchase classes and begin reserving. We accept Mastercard, Visa, American Express, and
            cash.
          </p>

          <p>
            <strong className="faq-question">When does the booking window open?</strong>
            Weekly schedules are released every Monday morning at 10am.
          </p>
          <p>
            <strong className="faq-question">When do classes need to be cancelled?</strong>
            Classes must be cancelled 12 hours before the scheduled class or your credit will be
            forfeited. Classes can be cancelled online or by calling the studio at (212) 242-3330.
          </p>
          <p>
            <strong className="faq-question">
              How will I know when I am taken off the waitlist and added to a class?
            </strong>
            You will receive an email and a call notifying you. If you are on the waitlist, but do
            not get into the class, your class credit will be placed back into your account. Should
            you decide that you are not able to take a class that you are on the waitlist for,
            please be sure to remove yourself from the waitlist to avoid losing your credit. You
            will receive an email and a call notifying you. If you are on the waitlist, but do not
            get into the class, your class credit will be placed back into your account. Should you
            decide that you are not able to take a class that you are on the waitlist for, please be
            sure to remove yourself from the waitlist to avoid losing your credit.
          </p>
          <p>
            <strong className="faq-question">Can I purchase gift certificates?</strong>
            Yes. You can purchase gift certificates online or in the studio. To purchase online,
            please click here, enter your preferred amount, tell us when you’d like it to be
            delivered, add a personal note and we’ll handle the rest.
          </p>
          <p>
            <strong className="faq-question">Do you offer student discounts?</strong>
            We do and we’d love to tell you more about them. Please call the studio for more
            information (212.242.3330).
          </p>
          <p>
            <strong className="faq-question">
              Are there are any age requirements for riding at SWERVE?
            </strong>
            Yes. We do not permit children under the age of 13 to ride at SWERVE. For riders aged
            13-18 years old, you must be accompanied by an adult or present a signed liability form
            at your first ride if you will not be accompanied by an adult.
          </p>
        </div>
      </div>
    </main>
  );
};

export default FAQ;
