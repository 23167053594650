import React from "react";

const TheBike = () => {
  return (
    <main id="theBike">
      <div className="theBike-hero">
        <img src="/images/thebike-hero.jpg" alt="Man riding the SWERVE bike" />
      </div>
      <div className="theBike-postHero">
        <div className="theBike-intro">
          <div className="theBike-intro-inner">
            <h1>
              More Power
              <br />
              More Progress
            </h1>
            <p>
              Meet your new training partner: the Bike. It’s an at-home workout experience powered
              by Swerve’s elite trainers, scientifically-proven training methods, and motivational
              playlists. Tune into live or recorded classes from your home.
            </p>
          </div>
        </div>
        <div className="theBike-buttons buttonPair">
          <a href="/bike" className="button">
            Browse the bike
          </a>
          <a href="#" className="button button_outline">
            4 ways to ride
          </a>
        </div>
        <div className="theBike-verticalLine"></div>
        <div className="theBike-swervePlusTechnogym">
          <h1>Swerve + Technogym</h1>
          <p>
            When you combine Swerve’s proven training method with Technogym’s unrivaled bike
            technology you get an unprecedented experience:
          </p>
        </div>
        <div className="theBike-photoAndStats">
          <img
            src="/images/thebike-greybg.jpg"
            alt="The SWERVE bike"
            className="theBike-photoAndStats-photo"
          />
          <div className="theBike-photoAndStats-stats">
            <div className="theBike-photoAndStats-stats-stat">
              <h4>Motivational Metrics</h4>
              <p>See your progress and strive for new personal bests.</p>
            </div>
            <div className="theBike-photoAndStats-stats-stat">
              <h4>Team Competition</h4>
              <p>Benefit from a framework that breeds motivation.</p>
            </div>
            <div className="theBike-photoAndStats-stats-stat">
              <h4>Playlists That Push</h4>
              <p>Get in the zone with music made for movement.</p>
            </div>
            <div className="theBike-photoAndStats-stats-stat">
              <h4>Elite Trainers</h4>
              <p>Follow the lead of people who are after the same thing you are: results.</p>
            </div>
            <div className="theBike-photoAndStats-stats-stat">
              <h4>Training Programs</h4>
              <p>Develop a routine that works for you.</p>
            </div>
          </div>
        </div>
        <div className="theBike-finalCTA">
          <h3>Every bike purchase contributes to something bigger.</h3>
          <a href="#" className="button">
            Buy the bike
          </a>
        </div>
      </div>
    </main>
  );
};

export default TheBike;
