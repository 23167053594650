import React from "react";

const TOS = () => {
  return (
    <main id="toc">
      <div className="textPage swooshBG">
        <div className="textPage-copyBlock">
          <h1>Terms of Service</h1>
          <div className="tos-section">
            <p>
              <strong>SWERVE FITNESS</strong>
            </p>
            <p>Terms & Conditions of Purchase</p>
            <p>
              These Terms & Conditions of Purchase apply to everybody who purchases any type of
              class, series of classes or package of classes (“Purchaser”).
            </p>
          </div>
          <div className="tos-section">
            <p>
              <strong>Section 1: Fees and Payments</strong>
              Purchaser’s Obligation. Purchaser shall not be relieved of the obligation to make
              payments agreed to, and no deduction from any payment shall be made because of
              Purchaser’s failure to use the facilities of SWERVE Fitness or any fitness video
              content made virtually available by SWERVE through its website (the “Studio”).
            </p>
            <p>
              Form of Payment. All payments (including per class payments and incidentals) are
              payable by electronic funds transfer from the Purchaser’s credit card account or debit
              card account. Purchaser must authorize payments to be made through a third party
              administered electronic funds transfer system. Purchaser maintains full control and
              privacy over his or her account at all times, and the transfer of funds affects only
              those fees that have Purchaser’s prior authorization and when a Purchaser authorizes
              any additional transaction, including but not limited to single classes, class series,
              class packages, and retail or smoothie bar transactions either on the Studio’s website
              or in the Studio’s facilities, as well as charges associated with late cancellation
              and/or no-shows.
            </p>
            <p>
              Returned Payment Penalty. Purchaser will automatically be charged any bank fee imposed
              on the Studio, plus a processing fee, for any returned payment item due to closed
              accounts, insufficient funds or similar issue. On such occasion, the Studio retains
              the right to collect the current and past due balances in any subsequent month.
            </p>
            <p>
              Disputed Charge Penalty. In the event that a Purchaser initiates a credit card dispute
              that is subsequently resolved in the Studio’s favor, the Studio shall have the right
              to charge Purchaser up to $50 per occurrence.
            </p>
            <p>
              Cancellation of Classes. Purchaser may cancel his or her reservation (either on the
              Studio’s website www.swervefitness.com, or by calling the Studio at the phone number
              specified on the Studio’s website) no later than twelve (12) hours prior to the
              reserved class in order to retain the full class credit. Classes not cancelled prior
              to that time will be revoked.
            </p>
          </div>
          <div className="tos-section">
            <strong>Section 2: Classes</strong>
            <p>
              Revocation of Classes. The Studio (in its sole discretion) may revoke Purchaser’s
              class, class series or class credits, at any time and without refund, in the event
              that Purchaser engages in behavior that is unsafe or objectionable to other
              participants or staff, or for reasons of nuisance, disturbance to other participants
              or staff, moral turpitude or fraud, or personal hygiene and attire. The Studio also
              reserves the right to require Purchaser to leave for the day if, in the Studio’s
              reasonable judgment, Purchaser poses a health or safety risk to Purchaser or others,
              or is disturbing or likely to disturb other participants or staff.
            </p>
            <p>
              Use Privileges. Purchaser must abide by the individual rules of the Studio.
              Additionally, the Studio (in its sole discretion) reserves the right to revoke or
              suspend Purchaser’s class, class series or class credits if Purchaser has purchased a
              class, class series or class packages, and allows persons other than Purchaser to use
              Purchaser’s account to book and/or attend classes.
            </p>
          </div>
          <div className="tos-section">
            <strong>Section 3: Purchaser Risk</strong>
            <p>
              Medical Recommendations. Purchaser is strongly advised to consult with his or her
              physician or to have a physical examination before using any of the Studio’s
              facilities or enrolling in any of the Studio’s classes, including fitness video
              classes offered virtually via the Studio's website, especially if Purchaser is
              elderly, pregnant, has a history of heart disease, high blood pressure or other
              chronic illness, or is unaccustomed to strenuous physical exertion or has any other
              physical limitations that could create an increased risk of injury or adverse health
              consequences from strenuous exercise.
            </p>
            <p>
              Orientation. Purchaser is strongly encouraged to arrive early prior to their first
              ride as well as request ongoing support available at the Studio to ensure the proper
              and safe use of all of the Studio’s equipment.
            </p>
            <p>
              Purchaser Conduct. Purchaser shall not use any Studio facility, service or equipment
              in such a way as to endanger the health or safety of Purchaser or others. Purchaser
              shall be responsible for any property damage or personal injury caused by Purchaser
              and/or his or her guests. Purchaser agrees not to violate any laws while in any Studio
              facility and agrees further to abide by all of the Studio’s rules and regulations, as
              they now exist and as they may be amended from time to time in the discretion of the
              Studio.
            </p>
            <p>
              Purchaser Assumption of Risk. Purchaser acknowledges that his or her participation in
              the Studio’s classes (including fitness video classes offered virtually via the
              Studio's website) and use of the Studio’s facilities necessarily involves a risk of
              severe, permanent physical injury (including, without limitation, strained, sprained
              or torn muscles, tendons or ligaments, broken bones, dislocation of joints,
              concussion, brain damage, nerve and spinal cord injury, and paralysis) and death. By
              enrolling or participating in any of the Studio’s classes, or otherwise using any of
              the Studio’s facilities, PURCHASER WILLINGLY AND VOLUNTARILY ASSUMES ALL SUCH RISKS.
              The Studio cannot, and does not, guarantee that any facility, class or equipment is
              free of risk of any and all accidents or injuries of any kind (including death).
            </p>
            <p>
              Medical Disclaimer. Purchaser has been informed and acknowledges that the Studio makes
              no claims as to medical or fitness results that can or may be obtained through use of
              the Studio’s facilities, equipment or services. The Studio has neither suggested nor
              will suggest any medical treatment to Purchaser. Only licensed medical professionals
              are qualified to give medical advice.
            </p>
            <p>
              Purchaser’s Health Warranty. By enrolling or participating in any of the Studio’s
              classes, or otherwise using any of the Studio’s facilities, Purchaser represents and
              warrants that he or she (i) is in good medical and physical condition, and that
              participation in the classes or use of the facilities does not pose any danger to
              Purchaser’s health; (ii) has no medical or physical conditions that would preclude his
              or her participation in any of the Studio’s classes or use of the Studio’s facilities,
              or otherwise create an increased risk of injury or adverse health consequences as a
              result of strenuous exercise; and (iii) has not been instructed or advised by any
              physician against participating in strenuous physical exercise or exertion,
              participating in any of the Studio’s classes, or using the Studio’s facilities.
            </p>
            <p>
              Purchaser Waiver, Release and Indemnity. By checking the box to signify agreement with
              the Terms and Conditions of Purchase and/or by attending classes or otherwise
              participating in Studio activities, Purchaser, on behalf of his- or herself, and on
              behalf of his or her heirs and assigns, expressly agrees to forever discharge, waive
              and release SWERVE Fitness Holdings LLC, JMEP LLC dba SWERVE Fitness, SWERVE NYC 46TH
              LLC, SWERVE NYC UES LLC, and each of its subsidiaries and their respective members,
              officers, directors, affiliates, instructors, agents, employees and/or independent
              contractors (the “Released Parties”) from any and all claims, demands, injuries,
              liabilities, actions, causes of action and from all acts of active or passive
              negligence on the part of the Released Parties on account of any and all injuries or
              damages, including but not limited to bodily injury, mental injury, death and/or
              property damage from any event, mishap, accident, loss, damage or injury suffered
              resulting from or connected with or caused by the use of the Studio’s classes,
              services and facilities (including, without limitation, any of the bikes, locker
              rooms, or other equipment). Purchaser further agrees, to the fullest extent permitted
              by law, to defend, indemnify and hold harmless the Released Parties from any and all
              claims, losses and liabilities arising from, connected to, and/or arising from his or
              her use of the Studio’s classes, services and facilities (including, without
              limitation, any of the bikes, locker rooms, or other equipment.) Additionally,
              Purchaser and his or her guests shall hold the Released Parties harmless from any
              loss, theft, cost, claim, injury, damage or liability incurred as a result of their
              use of the Studio and associated activities.
            </p>
            <p>
              Prevailing Party. In the event Purchaser commences an action against the Studio or its
              members, officers, affiliates, agents or employees and fails to obtain judgment or
              partial judgment in Purchaser’s favor, Purchaser shall be liable to the Studio for all
              costs and expenses associated with Studio’s defense of the action or any claims on
              which Purchaser did not prevail, including, without limitation, reasonable attorneys’
              fees and costs.
            </p>
            <p>
              Costs of Collection. Purchaser agrees to pay all costs plus reasonable attorney and
              collection fees in connection with Studio’s (or Studio’s designee’s) collection of any
              amounts owed by Purchaser.
            </p>
            <p>
              Loss of Property. Purchaser is urged not to bring valuables onto the Studio premises
              and to keep valuables with him or her at all times. The Studio shall not be liable for
              the disappearance, loss or theft of, or damage to, personal property (including money,
              negotiable securities and jewelry).
            </p>
          </div>
          <div className="tos-section">
            <strong>Section 4: General Provisions</strong>
            <p>
              Purchaser and Guests Rules. Purchaser and his or her guests shall abide by the
              Studio’s rules and regulations and any amendments and/or modifications thereto.
            </p>
            <p>
              Lockers. Lockers are provided solely for the benefit and convenience of the
              participants. The Studio will remove any articles left in a locker overnight.
            </p>
            <p>Pets and Bicycles. Pets and bicycles are not permitted in the Studio.</p>
            <p>
              Dress Code. Proper athletic attire is required. The Studio reserves the right to make
              the final determination in its sole discretion with regard to appropriate attire.
            </p>
            <p>
              Independent Contractors. From time to time, the Studio may make the services of
              independent contractors available to Purchaser and his or her guests. The Studio does
              not warrant or guarantee the quality of these services.
            </p>
            <p>
              Guest Rules. Guests are permitted in the Studio, but only pursuant to such rules, fees
              and schedules as then may be in effect. All guests must register at the front desk and
              sign the Studio’s standard release agreement.
            </p>
            <p>
              Special Events. The Studio may from time to time reserve the use of its facilities for
              special events, competitions and private functions.
            </p>
            <p>
              Change in Operating Hours/Temporary Closing of Studio. As a result of repair,
              maintenance, special occasions, or act of God, the Studio may be required to restrict
              the use or temporarily close its facilities or activities from time to time. Hours of
              operation may be amended from time to time, and certain facilities, services or
              programs may be added or eliminated from time to time, at the Studio’s discretion.
            </p>
            <p>
              Children’s Use. All children under 18 years of age must be accompanied by a parent at
              all times within the Studio unless they are in a supervised activity. Children under
              age 13 are not permitted in any Studio facility without the express permission of
              Studio management.
            </p>
          </div>
          <div className="tos-section">
            <strong>Section 5: Miscellaneous</strong>
            <p>
              Non-Discrimination. The Studio will not discriminate against any person because of
              gender, race, creed, age, color, sexual orientation, national origin or ancestry in
              considering applications for, or have taken other action in connection with, entrance
              in the Studio.
            </p>
            <p>
              Waiver. It is understood and agreed that no failure or delay by any party hereto in
              exercising any right, power or privilege hereunder shall operate as a waiver thereof,
              nor shall any single or partial exercise preclude any other or further exercise or the
              exercise of any right, power or privilege hereunder.
            </p>
            <p>
              Enforcement. If any provision of these Terms and Conditions of Purchase is held to be
              invalid or unenforceable by a court of competent jurisdiction, such holding shall not
              affect the validity or enforceability of any other provisions of this contract, which
              shall remain in full force and effect, and the provisions held invalid or
              unenforceable shall be deemed modified so as to give such provisions the maximum
              effect permitted by applicable law.
            </p>
            <p>
              Governing Law; Jurisdiction. This agreement shall be governed in all respects by the
              substantive law of the State of New York, without regard to its conflict of law
              principles. Purchaser hereby unconditionally consents to submit to the jurisdiction of
              the federal and state courts sitting in New York, New York for any actions, suits or
              proceedings arising out of or relating to this agreement.
            </p>
            <p>
              Entire Agreement. These Terms and Conditions of Purchase and all rules and regulations
              of the Studio, as revised from time to time, constitute the entire and exclusive
              agreement between the parties, and supersede all prior promises, representations,
              understandings and/or agreements relating to the subject matter hereof. This agreement
              may be modified only by an instrument in writing and only by the Studio.
            </p>
            <p>
              Agreement between user and SWERVE Fitness
              <br />
              Welcome to the SWERVE Fitness website, www.swervefitness.com (the “Site”). The Site is
              comprised of various web pages operated by SWERVE Fitness and is offered to you
              conditioned on your acceptance without modification of the terms, conditions, and
              notices contained herein (the "Terms"). Your use of the Site constitutes your
              agreement to all such Terms and SWERVE Fitness' Privacy Policy, found below under the
              section labeled "Privacy Policy." This agreement sets forth the legally binding terms
              for your use of the site. If you do not agree with all of the provision of this
              agreement, do not access and/or use the Site.
            </p>
            <p>
              Your account
              <br />
              To access certain features of this Site, you may have to create an account. You are
              responsible for maintaining the confidentiality of your account username and password
              and for restricting access to your computer, and you agree to accept responsibility
              for all activities that occur under your account. You are limited to only one account.
              You may not assign or otherwise transfer your account to any other person or entity.
              You acknowledge that SWERVE Fitness is not responsible for third party access to your
              account that results from theft or misappropriation of your account. You must notify
              SWERVE Fitness immediately of any breach of security or unauthorized use of your
              account. SWERVE Fitness and its associates reserve the right to refuse or cancel
              service, terminate accounts, or remove or edit content in our sole discretion.
            </p>
            <p>
              Any creation of an account by, use of or access to the Site by anyone under 13 is
              unauthorized, unlicensed and in violation of these Terms. By using the Site you
              represent and warrant that you are at least 13 years of age and that you agree to
              abide by all of the Terms. Anyone between the ages of 13 and 18 may use the Site and
              create an account only with permission of a parent or guardian.
            </p>
            <p>
              Cancellation / Refund Policy
              <br />
              Reserved bikes may be cancelled up until 12 hours prior to the scheduled class without
              penalty. Bikes can be cancelled by logging onto your account or calling the studio.
              Bikes not cancelled before 12 hours prior to the scheduled class will result in the
              loss of class credit.
            </p>
            <p>
              Links to third party sites/Third party services
              <br />
              The Site may contain links to other websites ("Linked Sites"). The Linked Sites are
              not under the control of SWERVE Fitness and SWERVE Fitness is not responsible for the
              contents of any Linked Site, including without limitation any link contained in a
              Linked Site, or any changes or updates to a Linked Site. SWERVE Fitness is providing
              these links to you only as a convenience, and the inclusion of any link does not imply
              endorsement by SWERVE Fitness of the site or any association with its operators.
            </p>
            <p>
              Certain services made available via the Site are delivered by third party sites and
              organizations. By using any product, service or functionality originating from the
              www.swervefitness.com domain, you hereby acknowledge and consent that SWERVE Fitness
              may share such information and data with any third party with whom SWERVE Fitness has
              a contractual relationship to provide the requested product, service or functionality
              on behalf of www.swervefitness.com users and customers.
            </p>
            <p>
              Mobile Services
              <br />
              The Site may include certain services that may be available via your mobile phone,
              including but not limited to (i) the ability to book and/or purchase services via your
              mobile phone, (ii) the ability to browse the Site from your mobile phone and (the
              “Mobile Services”). Your carrier's normal messaging, data and other rates and fees
              will apply.
            </p>
            <p>
              No Unlawful or Prohibited Use / Intellectual Property
              <br />
              You are granted a limited, non-exclusive, non-transferable, revocable license to
              access and use www.swervefitness.com strictly in accordance with these terms of use.
              As a condition of your use of the Site, you warrant to SWERVE Fitness that you will
              not use the Site for any purpose that is unlawful or prohibited by these Terms. You
              may not use the Site in any manner which could damage, disable, overburden, or impair
              the Site or interfere with any other party's use and enjoyment of the Site. You may
              not obtain or attempt to obtain any materials or information through any means not
              intentionally made available or provided for through the Site.
            </p>
            <p>
              All content included as part of the Service, such as text, graphics, logos, images,
              videos, as well as the compilation thereof, and any software used on the Site, is the
              property of SWERVE Fitness or its suppliers and protected by copyright and other laws
              that protect intellectual property and proprietary rights. You agree to observe and
              abide by all copyright and other proprietary notices, legends or other restrictions
              contained in any such content and will not make any changes thereto.
            </p>
            <p>
              You will not modify, publish, transmit, reverse engineer, participate in the transfer
              or sale, create derivative works, or in any way exploit any of the content, in whole
              or in part, found on the Site. SWERVE Fitness content is not for resale. Your use of
              the Site does not entitle you to make any unauthorized use of any protected content,
              and in particular you will not delete or alter any proprietary rights or attribution
              notices in any content. You will use protected content solely for your personal use,
              and will make no other use of the content without the express written permission of
              SWERVE Fitness and the copyright owner. You agree that you do not acquire any
              ownership rights in any protected content, and that You shall not broadcast or
              otherwise use any of the content for commercial purposes. We do not grant you any
              licenses, express or implied, to the intellectual property of SWERVE Fitness or our
              licensors except as expressly authorized by these Terms.{" "}
            </p>
            <p>
              Third Party Accounts
              <br />
              You will be able to connect your SWERVE Fitness account to third party accounts. By
              connecting your SWERVE Fitness account to your third party account, you acknowledge
              and agree that you are consenting to the continuous release of information about you
              to others (in accordance with your privacy settings on those third party sites). If
              you do not want information about you to be shared in this manner, do not use this
              feature.
            </p>
            <p>
              International Users
              <br />
              The Service is controlled, operated and administered by SWERVE Fitness from our
              offices within the USA. If you access the Service from a location outside the USA, you
              are responsible for compliance with all local laws. You agree that you will not use
              the SWERVE Fitness Content accessed through www.swervefitness.com in any country or in
              any manner prohibited by any applicable laws, restrictions or regulations.
            </p>
            <p>
              Indemnification
              <br />
              You agree to indemnify, defend and hold harmless SWERVE Fitness, its officers,
              directors, employees, agents and third parties, for any losses, costs, liabilities and
              expenses (including reasonable attorneys' fees) relating to or arising out of your use
              of or inability to use the Site or services, any user postings made by you, your
              violation of any terms of this Agreement or your violation of any rights of a third
              party, or your violation of any applicable laws, rules or regulations. SWERVE Fitness
              reserves the right, at its own cost, to assume the exclusive defense and control of
              any matter otherwise subject to indemnification by you, in which event you will fully
              cooperate with SWERVE Fitness in asserting any available defenses.
            </p>
            <p>
              Liability Disclaimer
              <br />
              THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE
              SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED
              TO THE INFORMATION HEREIN. SWERVE FITNESS AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS
              AND/OR CHANGES IN THE SITE AT ANY TIME.
            </p>
            <p>
              SWERVE FITNESS AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY,
              RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE,
              PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE
              MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS,
              SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF
              ANY KIND. SWERVE FITNESS AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND
              CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
              GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
            </p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL SWERVE FITNESS
              AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
              SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
              LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY
              CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE
              THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR
              ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH
              THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT,
              TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF SWERVE FITNESS OR ANY OF ITS
              SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
              STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
              CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU
              ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR
              SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default TOS;
