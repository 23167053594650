import React, { useState } from "react";

const Header = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <header>
      <div className="header-announcementBar">
        <a href="https://blacklivesmatter.com/" rel="noopener noreferrer" target="_blank">
          20% OF PROCEEDS DONATED TO BLACK LIVES MATTER
        </a>
      </div>
      <div className="header_small">
        <a href="/">
          <img src="/images/logo.svg" alt="SWERVE Fitness logo" className="header-logo" />
        </a>
        <button
          alt="Mobile menu open/close icon"
          aria-controls="mobileModal"
          aria-haspopup="true"
          className="header_small-navToggle"
        ></button>
      </div>
      <div className="header_small-modal" id="mobileModal" role="tabpanel">
        <div className="header_small-modal-mainLinks">
          <a className="header-link" href="/trainers">
            Training
          </a>
          <a className="header-link" href="/bike">
            Bike
          </a>
          <a href="#" className="button">
            Book Now
          </a>
        </div>
        <div className="header_small-modal-secondaryLinks">
          <a href="/schedule" className="header-secondary-link header-secondary-link_schedule">
            Schedule
          </a>
          <a href="/trainers" className="header-secondary-link header-secondary-link_trainers">
            Trainers
          </a>
          <a href="/packages" className="header-secondary-link header-secondary-link_packages">
            Packages
          </a>
          <a
            href="/private-events"
            className="header-secondary-link header-secondary-link_privateevents"
          >
            Private Events
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.zingfit.SWERVE_Fitness&hl=en_US&gl=US"
            className="header-secondary-link header-secondary-link_app"
            rel="noopener noreferrer"
            target="_blank"
          >
            Try App
          </a>
        </div>
        <div className="header_small-modal-copyright">
          <span>
            ©&nbsp;Swerve&nbsp;2020,&nbsp;Swerve&nbsp;Fitness,&nbsp;Inc.
            <br />
            All&nbsp;rights&nbsp;reserved.
          </span>
          <img alt="Swerve symbol" draggable="false" src="/images/swerve-symbol-gray.svg" />
        </div>
      </div>
      <div className="header_large">
        <div className="header_large-mainBar">
          <a href="/">
            <img src="/images/logo.svg" alt="SWERVE Fitness logo" className="header-logo" />
          </a>
          <div className="header_large-mainBar-links">
            <a className="header-link" href="/trainers">
              Training
            </a>
            <a className="header-link" href="/the-bike">
              Bike
            </a>
          </div>
          <a href="#" className="button button_outline">
            Book Now
          </a>
        </div>
        <div className="header_large-lowerBar">
          <a href="/schedule" className="header-secondary-link header-secondary-link_schedule">
            Schedule
          </a>
          <a href="/trainers" className="header-secondary-link header-secondary-link_trainers">
            Trainers
          </a>
          <a href="/packages" className="header-secondary-link header-secondary-link_packages">
            Packages
          </a>
          <a
            href="/private-events"
            className="header-secondary-link header-secondary-link_privateevents"
          >
            Private Events
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.zingfit.SWERVE_Fitness&hl=en_US&gl=US"
            className="header-secondary-link header-secondary-link_app"
            rel="noopener noreferrer"
            target="_blank"
          >
            Try App
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
