import React from "react";

const Trainers = () => {
  return (
    <main id="trainer">
      <div className="trainer-hero">
        <img src="/images/trainer3-temp.jpg" alt="Trainer portrait" />
        <div className="trainer-hero-name">
          <span className="trainer-hero-name-first">Eddy</span>
          <span className="trainer-hero-name-last">Cavazos</span>
        </div>
      </div>
      <div className="trainer-postHero swooshBG">
        <div className="trainer-info">
          <div className="trainer-info-unit">
            <h2>Weslaco, TX</h2>
            <span>Hometown</span>
          </div>
          <div className="trainer-info-unit">
            <h2>Music</h2>
            <span>Top 40 Hits, EDM, throwbacks, and hip-hop.</span>
          </div>
          <div className="trainer-info-unit">
            <h2>Social Media</h2>
            <div className="trainer-info-socialMedia-links">
              <a
                href="https://twitter.com/swerve_fitness"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/images/social-icon-twitter.svg" />
              </a>
              <a
                href="https://www.instagram.com/swervefitness/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/images/social-icon-instagram.svg" />
              </a>
              <a
                href="https://www.facebook.com/swervefit/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="/images/social-icon-facebook.svg" />
              </a>
            </div>
          </div>
        </div>
        <div className="trainer-photoBioQuote">
          <img
            alt="Eddy training"
            className="trainer-photoBioQuote-photo"
            src="/images/trainer4-temp.jpg"
          />
          <div className="trainer-photoBioQuote-bioQuote">
            <span className="trainer-photoBioQuote-bioQuote-bio">
              Eddy loves what he does&mdash;and it shows. Show up, get lost in the music, and find
              yourself stronger on the other side.
            </span>
            <span className="trainer-photoBioQuote-bioQuote-quote">
              Unless they paying your bills, pay them b*tches no mind.
              <span className="trainer-photoBioQuote-bioQuote-quote-attribution">RuPaul</span>
            </span>
            <a href="#" className="button">
              Book a session with Eddy
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Trainers;
